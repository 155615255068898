<template>
  <!--begin::Mixed Widget 18-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 my-lg-0">
      <div class="card-title">
        <div class="card-label font-weight-bolder">
          {{ $t('dashboard.debt.title') }}
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body Active-->
    <div v-if="debt > 0 || adminInCompanies.length > 0 || companyMember.length > 0 || !loaded"
         class="card-body d-flex flex-column flex-grow pt-0">
      <!--begin::Short Info-->
      <div v-if="loaded" class="card-body-content">
        <template v-if="adminInCompanies.length === 0 && debt === 0">
          {{ $t('dashboard.debt.no_rights') }}
        </template>
        <p v-if="debt > 0 || companyWithDebt.length > 0">{{ $t('dashboard.debt.subtitle') }}</p>
        <div v-if="debt > 0" class="mb-5">
          <span> {{ $t('dashboard.debt.personal_debt') }}</span>
          <h2 class="font-weight-bolder">
            <font-awesome-icon icon="wallet" class="text-danger icon-md mr-1"/>
            {{ $t('dashboard.debt.debt_value', {debt: debt}) }}
          </h2>
        </div>
        <template v-if="companyWithDebt">
          <div v-for="(company, index) in companyWithDebt" :key="index">
            <span> {{ $t('dashboard.debt.company_debt') }} {{ company.name }}</span>
            <h2 class="font-weight-bolder">
              <font-awesome-icon icon="wallet" class="text-danger icon-md mr-1"/>
              {{ $t('dashboard.debt.debt_value', {debt: company.debt}) }}
            </h2>
          </div>
        </template>
      </div>
      <PuSkeleton v-else />
      <!--end::Short Info-->
      <router-link v-if="loaded" to="/order?status=7" class="btn btn-block btn-light-primary mt-auto">
        {{ $t('dashboard.debt.all_orders') }}
      </router-link>
      <PuSkeleton v-else />
    </div>
    <!--end::Body Active-->
    <!--begin::Body InActive-->
    <div v-else class="card-body pt-0">
      <!--begin::Short Info-->
      <div class="d-flex align-items-center mr-2">
        <!--begin::Symbol-->
        <div class="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
          <div class="symbol-label">
            <font-awesome-icon icon="check" class="text-success icon-md"/>
          </div>
        </div>
        <!--end::Symbol-->
        <!--begin::Title-->
        <div>
          <div>{{ $t('dashboard.debt.inactive') }}</div>
        </div>
        <!--end::Title-->
      </div>
    </div>
    <!--end::Body InActive-->
  </div>
  <!--end::Mixed Widget 18-->
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      orders: 'dashboard/orders',
    }),
    ...mapGetters( `user`, {
      identity: `identity`,
    }),
  },
  data: () => {
    return {
      ts_start: null,
      ts_end: null,
      debt: 0,
      order_list: [],
      belongsCompanies: [],
      adminInCompanies: [],
      companyMember: [],
      companyWithDebt: [],
      loaded: false,
    }
  },
  mounted() {
    this.$store.dispatch('user/GetCompanies').then(res => this.belongsCompanies = res);
  },
  methods: {
    ...mapActions('dashboard', [
      'OrdersInit'
    ]),
    onAdminSelected() {
      if (this.adminInCompanies.length > 0 && this.loaded === true) {
        this.adminInCompanies.forEach(id => {
          let list = [];
          this.orders.forEach(order => {
            if (id === order.company_id) {
              list.push(order);
            }
          })

          if (list.length > 0) {
            let company = this.belongsCompanies.find(item => item.id === list[0].company_id)
            let debt = null;
            for (let i in list) {
              debt = debt + list[i].total_cost;
            }
            this.companyWithDebt.push({
              id: company.id,
              name: company.name,
              debt: debt
            })
          }
        })
      }
    }
  },
  watch: {
    orders: {
      handler() {
        if (this.orders.length > 0) {
          this.identity.company_ids.forEach((item, idx, array) => {
            this.$store.dispatch('user/GetCompanyEmployees', {
              id: item,
              page: 1,
              perPage: 500,
            }).then(res => {
              res.forEach(i => {
                if (i.id === this.identity.id && i.role === 'administrator') {
                  this.adminInCompanies.push(item)
                } else if (i.id === this.identity.id && i.role === 'member') {
                  this.companyMember.push(item);
                }
              });
              // console.log(this.adminInCompanies);
              // console.log(this.companyMember);
              if (idx === array.length - 1){
                this.onAdminSelected();
              }
            });
          });

          // Personal debt
          for (let i in this.orders) {
            if (this.orders[i].company_id === null) {
              this.debt = this.debt + this.orders[i].total_cost || 0
              this.order_list.push({
                id: this.orders[i].id,
                created_at: moment.unix(this.orders[i].created_at).format('DD.MM.YYYY'),
                currency: this.orders[i].currency,
                comment: this.orders[i].comment,
              })
            }
          }
          // END: Personal debt
        }
        this.loaded = true
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
  .card-custom {
    height: 304px!important;
  }
  .card-body-content {
    overflow-y: scroll!important;
  }
  @media (max-width: 991px) {
    .card-custom {
      height: 343px!important;
    }
  }
</style>
